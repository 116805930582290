import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import marked from "marked"

//faq accordion
// import { Accordion, Card } from "react-bootstrap"

const OrganizationalConsultation = () => (
  <Layout>
    <SEO
      title="Organizational Consultation"
      description="JBL Sourcing is a provider of full-service recruitment, retention, and other consultative solutions"
    />
    <div
      className="page-headline-orgcont"
      
    >
      <div className="container">
        <div className="section-heading text-center">
          <h6 className="font-weight-bold text-uppercase text-white flair">
            Services
          </h6>
          <h1 className="text-white">Organizational Consultation</h1>
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="mb-5">
              <h2 className="text-center p-0 mt-3 mb-2">
                Provider of full-service recruitment, retention, and other
                consultative solutions.
              </h2>
              <div className="heading-line2 mx-auto" />
            </div>
            <p>
              JBL Sourcing is a provider of full-service recruitment, retention,
              and other consultative solutions. Our niche area integrates us at
              the highest levels of leadership. Our clients enjoy a full array
              of solutions customized to meet their unique and individual needs.
              Our niche area integrates us at the highest levels of leadership.
              Our clients enjoy a full array of solutions customized to meet
              their unique and individual needs.
            </p>

            <p>
              We listen carefully to our clients’ corporate desired outcome. We
              identify the best course of action and work with diligence to
              provide the solution. We measure our success by solving client
              problems and putting the best person in the right position.
            </p>
            <p>
              We build strong relationships with our clients that get to the
              heart of achieving their objectives and creating pathways to
              success.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default OrganizationalConsultation
